import styled from 'styled-components';
import React from 'react';

const Item = styled.li`
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  color: #707070;
  list-style: none;
  background-color: #1f1f1f;
  margin: 8px auto;
  border-radius: 8px;
  padding: 12px 24px;
  width: 400px;

  span {
    float: right;
  }
`;

const Header = styled.h1`
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #707070;
`

const List = styled.ul`
  width: 100%;
  height: 300px;
  overflow: scroll;
  position: fixed;
  top: 0;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 24px;
`;

const TaskList = ({tasks}) => {

  let taskList = [];
  
  if (Object.keys(tasks).length) {
    let lastDate = { date: null, time: 0 };
    for (const task in tasks) {
      taskList.push({...tasks[task], id: task});
      if(lastDate.date && new Date(tasks[task].date).getDate() !== new Date(lastDate.date).getDate()) {
        taskList.push({...lastDate});
        lastDate = { date: null, time: 0}
      } else {
        lastDate = { date: tasks[task].date, time: lastDate.time + tasks[task].time}
      }
    }
    taskList.push(lastDate);
  
    taskList.sort((a, b) => a.date < b.date ? 1 : -1)
  }

  return (<List>
    { taskList.map(task => {
      const seconds = task.time % 60;
      const minutes = Math.floor(task.time / 60);
      const timeText = `${minutes < 10 ? '0': ''}${minutes}:${seconds < 10 ? '0': ''}${seconds}`;
      return task.id ? <Item key={task.id}>{task.task} <span>{timeText}</span></Item>: <Header key={task.date}>{new Date(task.date).toDateString()} ({timeText})</Header>;
    })}
  </List>);
}

export default TaskList;