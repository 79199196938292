import styled from 'styled-components';

const NoPermission = styled.h1`
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  color: red;
  margin: 0;
`;

export default NoPermission;