import styled from 'styled-components';

const TaskInput = styled.input`
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  border: 2px solid ${ props => props.error ? 'red': '#707070' };
  padding: 12px 18px;
  width: 300px;
  outline: none;
  cursor: pointer;
  opacity: 0.7;
  border-radius: 10px;
  color: white;
  font-family: 'Rubik', sans-serif;
  text-align: center;
  font-size: 14px;

  :hover {
    opacity: 1;
  }
  :focus {
    opacity: 1;
  }
`;

export default TaskInput;