import styled from 'styled-components';

const TimerControlButton = styled.button`
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
`;

export default TimerControlButton;