import React, { useEffect, useReducer, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Time from './components/Time/Time';
import TaskList from './components/TaskList/TaskList';
import TaskInput from './components/TaskInput/TaskInput';
import NoPermission from './components/NoPermission/NoPermission';
import TimerControlButton from './components/TimerControlButton/TimerControlButton';
import { v4 as uuidv4 } from 'uuid';
import notificationSound from './assets/notification.wav'
import notificationBadge from './assets/logo192.png'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`

const AppWrapper = styled.div`
  background-color: #2F2F2F;
  width: 100%;
  height: 100vh;
`;

const availableTimes = [15, 30, 45, 60];
const notificationTone = new Audio(notificationSound);

const saveData = (data) => {
  localStorage.setItem('state', JSON.stringify(data));
}

const App = () => {
  const initialState = JSON.parse(localStorage.getItem('state')) || { isTimerRunning: false, timerEnd: 0, currentTimeIndex: 1, tasks: {}, currentTask: '', counter: 0};
  initialState.isTimerRunning = false;
  initialState.currentTask = '';
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case 'TOGGLE_TIMER': return { ...state, timerEnd: (Date.now() + (availableTimes[state.currentTimeIndex] * 60 * 1000)), isTimerRunning: !state.isTimerRunning };
      case 'RESET': return { ...state, isTimerRunning: false};
      case 'NEXT_TIME': return { 
        ...state, 
        currentTimeIndex: (state.currentTimeIndex + 1) % availableTimes.length,
      };
      case 'ADD_TASK': 
        const stateWithNewTask =  {
          ...state,
          tasks: {
            ...state.tasks,
            [uuidv4()]: { task: state.currentTask, time: availableTimes[state.currentTimeIndex] * 60, date: Date.now() },
          },
          currentTask: '',
        };
        saveData(stateWithNewTask);
        return stateWithNewTask;
      case 'REMOVE_TASK': const newState = {...state};
        delete newState.tasks[action.id];
        return newState;
      case 'SET_TASK' : return { ...state, currentTask: action.value };
      case 'CLEAR_TASK' : return { ...state, currentTask: '' };
      case 'EVERY_SECOND': return { ...state, counter: state.counter + 1 };
      default: return state;
    }
  }, initialState);
  const [error, setError] = useState(false);
  const [permission, setPermission] = useState(true);

  let intervalManager;

  const onEverySecond = () => {
    dispatch({ type: 'EVERY_SECOND' })
  }

  useEffect(() => {
    if (state.isTimerRunning) {
      intervalManager = setInterval(onEverySecond, 1000);
    } else {
      clearInterval(intervalManager);
      dispatch({ type: 'RESET' });
    }
    return () => clearInterval(intervalManager);

  }, [state.isTimerRunning]);

  useEffect(() => {
    if(state.isTimerRunning && Date.now() >= state.timerEnd) {
      document.title = 'TikTik | Sterrn';
      notificationTone.play();
      const notification = new  Notification('Time out!', { body: state.currentTask, icon: notificationBadge });
      dispatch({ type: 'ADD_TASK'})
      clearInterval(intervalManager);
      dispatch({ type: 'RESET' });
    }
  }, [state.counter]);

  useEffect(() => {
    Notification.requestPermission().then((p) => {
      p !== 'granted' && setPermission(false);
    });
  }, [])

  const timeSource = state.isTimerRunning ? parseInt((state.timerEnd - Date.now()) / 1000) : availableTimes[state.currentTimeIndex] * 60;

  const seconds = timeSource % 60;
  const minutes = Math.floor(timeSource / 60);
  const timeText = `${minutes < 10 ? '0': ''}${minutes}:${seconds < 10 ? '0': ''}${seconds}`;

  const changeTime = () => {
    if (state.isTimerRunning) {
      return;
    }

    dispatch({
      type: 'NEXT_TIME',
    })
  }

  const handleToggle = () => {
    if (state.currentTask || state.isTimerRunning) {
      document.title = state.isTimerRunning ? 'TikTik | Sterrn' : state.currentTask;
      dispatch({ type: 'TOGGLE_TIMER' });
      setError(false);
    } else {
      setError(true);
    }
  }

  return (
    <AppWrapper>
      <GlobalStyle/>
      { permission || <NoPermission>Please Enable Notification Permission</NoPermission>}
      <TaskList tasks={state.tasks}/>
      <Time disabled={state.isTimerRunning} onClick={changeTime}>{timeText}</Time>
      { state.isTimerRunning || <TaskInput onKeyDown={(e) => e.key === 'Enter' && handleToggle() } error={error} placeholder="Project: Task" value={state.currentTask} onChange={(e) => dispatch({ type: 'SET_TASK', value: e.target.value}) }/> }
      <TimerControlButton onClick={handleToggle}>
        {
          state.isTimerRunning
            ? <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="27" height="27" rx="4" fill="#707070" />
            </svg>
            : <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 4.47214C0 1.49861 3.12925 -0.435375 5.78885 0.894427L24.8446 10.4223C27.7928 11.8964 27.7928 16.1036 24.8446 17.5777L5.78885 27.1056C3.12925 28.4354 0 26.5014 0 23.5279V4.47214Z" fill="#707070" />
            </svg>
        }
      </TimerControlButton>
    </AppWrapper>
  );
}

export default App;