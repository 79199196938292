import styled from 'styled-components';

const Time = styled.h1`
  font-family: 'Black Ops One', cursive;
  font-size: 88px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: #707070;
  font-weight: normal;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  user-select: none;
`;

export default Time;